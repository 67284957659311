// 3rd-party imports

import React from "react";
import { Link } from "gatsby";

// local imports

import Layout from "../components/layout";
import SEO from "../components/seo";

// component

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not found (404)" />
    <h1>NOT FOUND</h1>
    <p>Nothing here :(</p>
    <p>
      <Link to="/">Go home</Link>
    </p>
  </Layout>
);

export default NotFoundPage;
